<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Backup Mode</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/gpm/backup_mode/create">
          <v-icon left dark>mdi-plus</v-icon>Activate Manually
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>

            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="users"
              :search="search"
            >
              <template v-slot:item.name="{ item }">
                <v-btn
                  color="primary"
                  text
                  :to="`/gpm/backup_mode/${item.id}`"
                  >{{ item.name }}</v-btn
                >
              </template>
              <template v-slot:item.StartTime="{ item }"
                >{{ item.StartDate | moment("LL") }}
                {{ item.StartTime | moment("LLL") }}</template
              >
              <template v-slot:item.EndTime="{ item }">{{
                item.EndTime | moment("LLL")
              }}</template>
              <template v-slot:item.Hours="{ item }"
                >{{ item.Hours }}Hrs {{ item.Minutes }}Mins</template
              >

              <template v-slot:item.Enabled="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                  >{{ item.Enabled == 1 ? "Active" : "In Active" }}</v-btn
                >
              </template>
              <template v-slot:item.mandatory="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeMandatory(item)"
                  >{{ item.mandatory == "Y" ? "Yes" : "No" }}</v-btn
                >
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn icon :to="`/gpm/backup_mode/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <snackbar refs="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      users: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Created By", value: "creator.name" },
        { text: "Start Day & Time", value: "StartTime" },
        { text: "End Day & Time", value: "EndTime" },
        { text: "Run Time", value: "Hours" },
        { text: "Status", value: "Enabled" },
        { text: "Actions", value: "action" },
      ],
    };
  },
  methods: {
    getFormFields() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/back_up_mode`)
        .then((res) => {
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    changeStatus() {},
    changeMandatory() {},
  },
  created() {
    this.getFormFields();
  },
};
</script>

<style scoped>
</style>